import { getFormProps, useForm, useInputControl } from "@conform-to/react";
import { getZodConstraint } from "@conform-to/zod";
import { useFetcher } from "@remix-run/react"
import { List, Tag } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { Field, FieldGroup, Label } from '#app/components/ui/catalyst/fieldset'
import { type Note } from '#app/components/ui/note/note-card'
import { CreateNoteFormSchema, type action as createNoteAction } from '#app/routes/_app+/_mutation+/create-note'
import { type loader as listLoader } from '#app/routes/_app+/list.$listId'
import { type loader as noteListsLoader } from '#app/routes/_app+/note_.$noteId_.lists'
import { type loader as tagLoader } from '#app/routes/_app+/tag.$tagIdOrSlug'
import { cn } from "#app/utils/misc.tsx";
import { Button } from "../catalyst/button";
import { Checkbox, CheckboxField } from "../catalyst/checkbox";
import Tiptap, { isOriginatedFromTiptap } from "../editor/tiptap";

interface NoteFormProps {
    autofocus: boolean
    onCancel?: () => void
    onNoteAdded?: (note: Note) => void
    className?: string
}

export const NoteForm = ({ autofocus, onCancel, onNoteAdded, className }: NoteFormProps) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [lastAnnouncedNoteId, setLastAnnouncedNoteId] = useState<string | null>(null)
    const createNoteFetcher = useFetcher<typeof createNoteAction>({ key: 'add-note' })
    const listOrTagLoader = useFetcher<typeof listLoader | typeof tagLoader | typeof noteListsLoader>()
    const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
    const listId = pathname.startsWith('/list/') ? pathname.split('/').pop()?.split('?')[0] : null
    const tagId = pathname.startsWith('/tag/') ? pathname.split('/').pop()?.split('?')[0] : null
    const noteId = pathname.startsWith('/note/') ? pathname.split('/').pop()?.split('?')[0] : null

    const formRef = useRef<HTMLFormElement>(null);
    const editorRef = useRef<HTMLDivElement>(null);
    const [form, fields] = useForm({
        id: 'add-note-form',
        lastResult: createNoteFetcher.data?.result,
        constraint: getZodConstraint(CreateNoteFormSchema),
        shouldRevalidate: 'onBlur',
    })
    const contentControl = useInputControl({
        formId: form.id,
        name: 'content'
    })

    const submitForm = useCallback(() => {
        if (createNoteFetcher.state !== 'idle') return
        createNoteFetcher.submit(formRef.current, {
            method: 'post',
            action: '/create-note',
        })
    }, [formRef, createNoteFetcher]);

    const submitFormForShortcut = useCallback((event: KeyboardEvent) => {
        if (!isLoaded) return false

        const isApple = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
        if ((isApple && event.metaKey && event.key === 'Enter') ||
            (!isApple && event.ctrlKey && event.key === 'Enter')) {
            event.preventDefault()
            submitForm()
            return true
        }
        return false
    }, [submitForm, isLoaded])

    // Setup keyboard shortcut handling.
    const handleFormKeyDown = useCallback((event: any) => {
        if (isOriginatedFromTiptap(event)) {
            return
        }
        submitFormForShortcut(event)
    }, [submitFormForShortcut])

    const handleTiptapKeyDown = useCallback((event: KeyboardEvent) => {
        if (submitFormForShortcut(event)) {
            return true
        }
        return false
    }, [submitFormForShortcut])

    useEffect(() => setIsLoaded(true), [])

    // Load list if needed
    useEffect(() => {
        if (!listId && !tagId && !noteId) {
            listOrTagLoader.data = undefined
            return
        }

        if (listOrTagLoader.state !== 'idle') return

        if (listId && listOrTagLoader.data && 'list' in listOrTagLoader.data && listOrTagLoader.data.list.id === listId) return
        if (tagId && listOrTagLoader.data && 'tag' in listOrTagLoader.data && listOrTagLoader.data.tag.id === tagId) return
        if (noteId && listOrTagLoader.data && 'noteId' in listOrTagLoader.data && listOrTagLoader.data.noteId === noteId) return

        if (listId) {
            listOrTagLoader.load(`/list/${listId}`)
        } else if (tagId) {
            listOrTagLoader.load(`/tag/${tagId}`)
        } else if (noteId) {
            listOrTagLoader.load(`/note/${noteId}/lists`)
        }
    }, [listId, tagId, noteId, listOrTagLoader])

    // Automatically close the dialog when the form is submitted.
    useEffect(() => {
        const invoke = () => {
            if (createNoteFetcher.data?.result?.status === 'success') {
                const note = createNoteFetcher.data?.note
                if (note && lastAnnouncedNoteId !== note.id) {
                    onNoteAdded?.({ ...note, updatedAt: new Date(note.updatedAt), createdAt: new Date(note.createdAt) })

                    // Reset form.
                    form.reset()
                    contentControl.change('')
                    setLastAnnouncedNoteId(note.id)
                }
            }
        }

        let to = setTimeout(invoke, 100)
        return () => clearTimeout(to)
    }, [createNoteFetcher.data, createNoteFetcher.state, onNoteAdded, lastAnnouncedNoteId, form, contentControl])

    // Autofocus the title field when the dialog is opened.
    useEffect(() => {
        const run = () => {
            if (!open || !autofocus) return;
            const input = document.getElementById('title-field');
            if (input) {
                input.focus();
            }
        };
        const timeout = setTimeout(run, 100);
        return () => clearTimeout(timeout);
    }, [autofocus]);

    return (
        <>
            <div className={cn([
                "w-full flex flex-col gap-0",
                className,
            ])}>
                <createNoteFetcher.Form
                    ref={formRef}
                    method='post'
                    action='/create-note'
                    className="relative flex flex-1 pb-3"
                    onKeyDown={handleFormKeyDown}
                    {...getFormProps(form)}
                >
                    <HoneypotInputs />
                    <FieldGroup className='!space-y-1 flex-grow w-full'>
                        <Field>
                            <label htmlFor="title-field" className="sr-only">
                                Title or Web URL
                            </label>
                            <div className="relative">
                                <input
                                    id="title-field"
                                    name="title"
                                    type="text"
                                    placeholder="Title"
                                    defaultValue={fields?.title?.value as string || ''}
                                    autoFocus={autofocus}
                                    className="block text-lg font-mono font-semibold h-full w-full border-0 bg-transparent p-1 text-foreground placeholder:text-foreground-placeholder focus:ring-0"
                                />
                                {fields?.title?.errors &&
                                    <div className="absolute inset-0 pointer-events-none text-end items-center flex text-sm text-red-500 justify-end">
                                        <span className="text-sm text-red-500 bg-black/5 px-2 py-0.5 rounded-md">
                                            {fields?.title?.errors}
                                        </span>
                                    </div>
                                }
                            </div>
                        </Field>
                        <Field>
                            <label htmlFor="content-field" className="sr-only">
                                Content
                            </label>
                            <input type='hidden' className='sr-only' name='content' value={contentControl.value ?? ''} tabIndex={-1} />
                            <Tiptap
                                ref={editorRef}
                                content={fields.content?.value as string ?? ''}
                                placeholder='Write your note...'
                                className='text-base p-1 min-h-[56px] max-h-[360px] overflow-auto'
                                onBlur={contentControl.blur}
                                onFocus={contentControl.focus}
                                onContentChange={(content) => {
                                    contentControl.change(content)
                                }}
                                onKeyDown={handleTiptapKeyDown}
                            />
                        </Field>
                        {listId && listOrTagLoader.data && 'list' in listOrTagLoader.data && listOrTagLoader.data.list && (
                            <AddToListField listId={listId} name={listOrTagLoader.data.list.name} />
                        )}
                        {tagId && listOrTagLoader.data && 'tag' in listOrTagLoader.data && listOrTagLoader.data.tag && (
                            <AddToTagField tagId={tagId} name={listOrTagLoader.data.tag.name} />
                        )}
                        {noteId && listOrTagLoader.data && 'lists' in listOrTagLoader.data && listOrTagLoader.data.lists && (
                            <AddToListsField lists={listOrTagLoader.data.lists} />
                        )}
                    </FieldGroup>
                </createNoteFetcher.Form>
                <div className='border-t border-dialog-border flex items-center gap-4 justify-end pt-3'>
                    {onCancel && (
                        <Button plain onClick={onCancel} disabled={createNoteFetcher.state !== 'idle'}>
                            Cancel
                        </Button>
                    )}
                    <CreateNoteButton
                        disabled={createNoteFetcher.state !== 'idle'}
                        onClick={submitForm}
                    />
                </div>
            </div>
        </>
    )
}

const CreateNoteButton = ({
    disabled,
    onClick,
}: {
    disabled: boolean,
    onClick: () => void,
}) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const isApple = isLoaded && /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
    const auxKey = isApple ? '⌘' : 'CTRL'
    useEffect(() => setIsLoaded(true), [])

    return (
        <Button
            disabled={disabled}
            onClick={onClick}
        >
            <div
                className='flex gap-3 items-center'
            >
                Create Note
                <div className='flex gap-1 text-xs font-normal text-white/80'>
                    <span className='px-1 py-0.5 bg-white/10 rounded-md'>{auxKey}</span>
                    +
                    <span className='px-1 py-0.5 bg-white/10 rounded-md'>Enter</span>
                </div>
            </div>
        </Button>
    )
}

const AddToListField = ({ name, listId }: { name: string, listId: string }) => {
    return (
        <div className="px-3 py-2 bg-black/5 rounded-md flex flex-col gap-y-1.5">
            <p className="text-sm font-semibold text-foreground-placeholder">Add to List</p>
            <Field>
                <CheckboxField>
                    <Checkbox
                        id={listId}
                        name='listId'
                        defaultChecked={true}
                        value={listId}
                    />
                    <Label
                        htmlFor={listId}
                        className="!text-base font-semibold truncate max-w-[100%] flex items-center gap-x-1.5"
                    >
                        <List size={16} className="flex-shrink-0" />
                        <span className="max-w-[100%] truncate">
                            {name}
                        </span>
                    </Label>
                </CheckboxField>
            </Field>
        </div>
    )
}

const AddToListsField = ({ lists }: { lists: { name: string, id: string }[] }) => {
    return (
        <div className="px-3 py-2 bg-black/5 rounded-md flex flex-col gap-y-1.5">
            <p className="text-sm font-semibold text-foreground-placeholder">Add to List</p>
            {lists.map(list => {
                return (
                    <Field key={list.id}>
                        <CheckboxField>
                            <Checkbox
                                id={list.id}
                                name='listId'
                                defaultChecked={true}
                                value={list.id}
                            />
                            <Label
                                htmlFor={list.id}
                                className="!text-base font-semibold truncate max-w-[100%] flex items-center gap-x-1.5"
                            >
                                <List size={16} className="flex-shrink-0" />
                                <span className="max-w-[100%] truncate">
                                    {list.name}
                                </span>
                            </Label>
                        </CheckboxField>
                    </Field>
                )
            })}
        </div>
    )
}

const AddToTagField = ({ name, tagId }: { name: string, tagId: string }) => {
    return (
        <div className="px-3 py-2 bg-black/5 rounded-md flex flex-col gap-y-1.5">
            <p className="text-sm font-semibold text-foreground-placeholder">Tag with</p>
            <Field>
                <CheckboxField>
                    <Checkbox
                        id={tagId}
                        name='tagId'
                        defaultChecked={true}
                        value={tagId}
                    />
                    <Label
                        htmlFor={tagId}
                        className="!text-base font-semibold truncate max-w-[100%] flex items-center gap-x-1.5"
                    >
                        <Tag size={16} className="flex-shrink-0" />
                        <span className="max-w-[100%] truncate">
                            {name}
                        </span>
                    </Label>
                </CheckboxField>
            </Field>
        </div>
    )
}