import { type SubmissionResult } from "@conform-to/react"
import { parseWithZod } from "@conform-to/zod"
import { type PrismaClient } from "@prisma/client"
import { type ITXClientDenyList } from "@prisma/client/runtime/library"
import { json, type TypedResponse, type ActionFunctionArgs } from "@remix-run/node"
import { z } from 'zod'
import { type Note } from "#app/components/ui/note/note-card.tsx"
import { extractUrls } from "#app/shared/extract-urls.server.ts"
import { addTask } from "#app/shared/queue.server.ts"
import { ANON_USER_ID, isAnonymousUserId, requireUserId } from "#app/utils/auth.server.ts"
import { buildStructuredImage } from "#app/utils/build-structured-image.server.ts"
import { prisma } from "#app/utils/db.server.ts"
import fetchMetadataFromUrl, { type UrlMetadata } from "#app/utils/fetch-metadata-from-url.server.ts"
import { checkHoneypot } from "#app/utils/honeypot.server.ts"
import { requireMethod } from "#app/utils/request.server.ts"
import { getAnonymousToken } from "#app/utils/session.server.ts"
import { slug } from "#app/utils/slug.server.ts"
import { syncNoteLinks } from "#app/utils/sync-note-links.server.ts"
import { UploadStatus } from "#app/utils/upload-status.server.ts"

export const CreateNoteFormSchema = z.object({
    title: z.string({ required_error: 'Title is required' }).min(1, { message: 'Title must not be empty' }),
    content: z.string().optional(),
    listId: z.optional(z.nullable(z.array(z.string()))),
    tagId: z.optional(z.nullable(z.string())),
})

interface CreateNoteResult {
    result: SubmissionResult<string[]>
    note?: Note
}

export const action = async ({ request }: ActionFunctionArgs): Promise<TypedResponse<CreateNoteResult>> => {
    requireMethod(request, ['POST'])

    const formData = await request.formData()
    checkHoneypot(formData)

    const userId = await requireUserId(request, true)
    const isAnonymousUser = isAnonymousUserId(userId)
    const anonymousToken = (await getAnonymousToken(request)).anonymousToken

    const submission = parseWithZod(formData, { schema: CreateNoteFormSchema });
    if (submission.status !== 'success') {
        return json(
            { result: submission.reply() },
            {
                status: submission.status === 'error' ? 400 : 200,
            },
        )
    }

    let { title, content, listId, tagId } = submission.value
    title = title.trim()
    content = content?.trim()
    listId = (listId ?? []).filter(s => s.trim()).filter(Boolean)
    tagId = tagId?.trim()

    if ((!listId || listId.length === 0) && isAnonymousUser) {
        // Special handling for anonymous user
        // This is when user create a note through the call to action in landing page
        // - Find existing list using the claimToken
        // - If not found, create a new one
        let anonymousList = await prisma.list.findFirst({
            where: {
                userId: ANON_USER_ID, claimToken: anonymousToken,
            }
        })
        if (!anonymousList) {
            anonymousList = await prisma.list.create({
                data: {
                    name: 'My Awesome List',
                    claimToken: anonymousToken,
                    userId: ANON_USER_ID,
                }
            })
        }
        listId = [anonymousList!.id]
    }

    // Handle case where the title is a URL.
    const titleURLs = extractUrls(title)
    let titleUrlMetadata: UrlMetadata | undefined = undefined
    if (titleURLs.length === 1 && titleURLs[0] === title) {
        try {
            titleUrlMetadata = await fetchMetadataFromUrl(titleURLs[0])
        } catch (e) {
            console.warn(
                `
                Failed to fetch URL metadata for '${titleURLs[0]}'.
                Error: ${e instanceof Error ? e.message : JSON.stringify(e, null, 2)}
                `
            )
        }
    }

    const note = await prisma.$transaction(async ($prisma) => {
        const slug = await generateSlug($prisma, title)
        const note = await $prisma.note.create({
            data: {
                slug,
                title: titleUrlMetadata?.title ?? title,
                content: content,
                userId: userId,
                claimToken: isAnonymousUser ? anonymousToken : null,
            }
        })

        if (listId.length > 0) {
            for (const id of listId) {
                // Make sure the list exists and belongs to the current user.
                const list = await $prisma.list.findFirst({
                    where: {
                        id, userId
                    },
                    select: { id: true },
                })

                if (list) {
                    await $prisma.note.update({
                        where: { id: note.id },
                        data: {
                            lists: { create: { listId: id } }
                        }
                    })
                }
            }
        }

        if (tagId && tagId.length > 0) {
            const tag = await $prisma.tag.findFirst({
                where: {
                    id: tagId, userId,
                },
                select: { id: true }
            })

            if (tag) {
                await $prisma.note.update({
                    where: { id: note.id },
                    data: {
                        tags: { connect: { id: tag.id } }
                    }
                })
            }
        }

        if (titleUrlMetadata) {
            await $prisma.noteLink.create({
                data: {
                    urlString: titleUrlMetadata.url,
                    title: titleUrlMetadata.title,
                    description: titleUrlMetadata.description,
                    imageUrlString: titleUrlMetadata.imageUrl,
                    faviconUrlString: titleUrlMetadata.faviconUrl,
                    domain: titleUrlMetadata.domain,
                    noteId: note.id,
                    isAuto: true,
                },
            })

            if (titleUrlMetadata.imageUrl) {
                let upload = await $prisma.upload.findFirst({
                    where: {
                        originUrlString: titleUrlMetadata.imageUrl
                    },
                    select: { id: true }
                })
                if (!upload) {
                    upload = await $prisma.upload.create({
                        data: {
                            originUrlString: titleUrlMetadata.imageUrl,
                            uploadStatus: UploadStatus.PENDING_UPLOAD,
                        },
                        select: { id: true }
                    })
                    await addTask('downloadToUploadPhoto', { uploadId: upload.id })
                }
                if (upload) {
                    const photo = await $prisma.notePhoto.create({
                        data: {
                            noteId: note.id,
                            uploadId: upload.id,
                        }
                    })
                    await $prisma.note.update({
                        where: {
                            id: note.id,
                            userId
                        },
                        data: {
                            coverId: photo.id
                        }
                    })
                }
            }
        }
        return await $prisma.note.findUniqueOrThrow({
            where: { id: note.id },
            include: {
                cover: { include: { upload: true } },
                photos: { include: { upload: true } },
                tags: true,
                lists: {
                    include: {
                        list: true,
                    },
                },
            }
        })
    })

    try {
        await syncNoteLinks(note.id)
    } catch (e) {
        console.warn(
            `
            Syncing note links failed (noteId=${note.id}).
            Error: ${e instanceof Error ? e.message : JSON.stringify(e, null, 2)}
            `
        )
    }

    return json({
        result: submission.reply(),
        note: {
            ...note,
            cover: note.cover
                ? buildStructuredImage({ id: note.cover.id, altText: note.cover.altText, upload: note.cover.upload })
                : null,
            photos: note.photos.map(p => buildStructuredImage({ id: p.id, altText: p.altText, upload: p.upload }))
                .filter(Boolean),
            lists: note.lists.map(noteList => ({
                ...noteList.list
            }))
        }
    })
}

const generateSlug = async (prisma: Omit<PrismaClient, ITXClientDenyList>, title: string) => {
    while (true) {
        const [candidate1, candidate2] = slug(title)

        const existingShare1 = await prisma.note.findUnique({ where: { slug: candidate1 } })
        if (!existingShare1) return candidate1!

        const existingShare2 = await prisma.note.findUnique({ where: { slug: candidate2 } })
        if (!existingShare2) return candidate2!
    }
}